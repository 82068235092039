import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import {
  defineRule as veeDefineRule,
  configure as veeConfigure,
} from "vee-validate";
import AllRules from '@vee-validate/rules';
import utils from "@/utils/utils.js";


//I18N 
const messages = utils.loadLocaleMessages();
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages
});

//Vee validate
Object.keys(AllRules).forEach(rule => {
  veeDefineRule(rule, AllRules[rule]);
});
veeConfigure({
  //If the montain doesn't going to Maoma...you blew the fucking mountain
  generateMessage: (context) => {
    let field = context.field;
    let rule = context.rule.name;
    let values = Object.assign({}, context.rule.params);
    field = field.replace(/\s+/g, "");
    //If the field doesnt exists, print null
    values.field = i18n.global.te('fields.' + field) ? i18n.global.t('fields.' + field) : null;
    return i18n.global.t('validation.' + rule, values);
  }
});

//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;
axios.interceptors.request.use(function (config) {
  if (store.state.auth.token) {
    config.headers["Authorization"] = "Bearer " + store.state.auth.token;
  }
  return config;
});


//Para evitar tener que esta revisando todos los errores contantemente
axios.interceptors.response.use(
  function (response) {
    if (response.data.status !== null && response.data.status === false) {
      if (response.data.error == null) {
        return store.commit('alert/launchAlert', i18n.t('generic_error'));
      } else {
        return Promise.reject(response);
      }

    }
    return response;
  },
  function (error) {
    if (error.response.status == 422) {
      let error_text = "";
      for (let er in error.response.data.validate_errors) {
        error_text += error.response.data.validate_errors[er].join(",") + ".";
      }
      store.commit('alert/launchAlert', error_text);
    } else {
      store.commit('alert/launchAlert', i18n.t('generic_error'));
    }
    return Promise.reject(error);
  }
);

//VUE 3 Mount
const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app')

store.$app = app;
store.$i18n = i18n.global;
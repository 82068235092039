<template>
  <div class="home">
    <p class="intro-categorias">
      Selecciona la categoría que desees consultar:
    </p>
    <ul class="listado-categorias row-between fww">
      <li class="categoria-el vademecums">
        <router-link :to="{ name: 'vademecums'}" class="row-center"
          ><span
            >Vademecum y Cat<span class="tilde-Manhattan">a</span>logo</span
          ></router-link
        >
      </li>

      <li class="categoria-el dossieres">
        <router-link :to="{ name: 'dossiers'}" class="row-center">Dossieres</router-link>
      </li>

      <li class="categoria-el fichas">
        <router-link :to="{ name: 'filesProduct'}" class="row-center"
          >Fichas de producto</router-link
        >
      </li>

      <li class="categoria-el newsletters">
        <router-link :to="{ name: 'news'}" class="row-center"
          >Newsletters</router-link
        >
      </li>
    </ul>
   
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
};
</script>

export default {
    namespaced: true,
    state: {
        alert: {
            active: false,
            text: null
        }
    },
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    mutations: { 
        launchAlert(state, newText) {
            state.alert.text = newText;
            state.alert.active = true;
        },
        closeAlert(state) {
            state.alert.text = null;
            state.alert.active = false;
        }
       
    }
}
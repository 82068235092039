export default {
  "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en estos momentos. Intentelo más tarde"])},
  "auth_errors": {
    "ex-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciales no válidas"])},
    "ex-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no validado"])},
    "ex-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token no valido"])}
  },
  "validation": {
    "alpha": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " solo debe contener letras"])},
    "alpha_dash": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " solo debe contener letras, números y guiones"])},
    "alpha_num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " solo debe contener letras y números"])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " solo debe contener letras y espacios"])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe estar entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1))])},
    "confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " no coincide"])},
    "digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser numérico y contener exactamente ", _interpolate(_list(0)), " dígitos"])},
    "dimensions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser de ", _interpolate(_list(0)), " píxeles por ", _interpolate(_list(1)), " píxeles"])},
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un correo electrónico válido"])},
    "excluded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un valor válido"])},
    "ext": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un archivo válido"])},
    "image": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser una imagen"])},
    "one_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un valor válido"])},
    "integer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un entero"])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El largo del campo ", _interpolate(_named("field")), " debe ser ", _interpolate(_list(0))])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " no debe ser mayor a ", _interpolate(_list(0)), " caracteres"])},
    "max_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe de ser ", _interpolate(_list(0)), " o menor"])},
    "mimes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser un tipo de archivo válido"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe tener al menos ", _interpolate(_list(0)), " caracteres"])},
    "min_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser ", _interpolate(_list(0)), " o superior"])},
    "numeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe contener solo caracteres numéricos"])},
    "regex": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El formato del campo ", _interpolate(_named("field")), " no es válido"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " es obligatorio"])},
    "required_if": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " es obligatorio"])},
    "size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, list: _list } = ctx;return _normalize(["El campo ", _interpolate(_named("field")), " debe ser menor a ", _interpolate(_list(0)), "KB"])}
  },
  "fields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNI"])},
    "nia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIA"])},
    "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIF"])},
    "cif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIF"])},
    "cnae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNAE"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepta Comunicaciones"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "surname_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer Apellido"])},
    "surname_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo Apellido"])},
    "surnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movil"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genero"])},
    "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumpleaños"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CP"])},
    "autonomous_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidad Autonoma"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidad"])},
    "municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque"])},
    "stair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalera"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piso"])},
    "door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pais"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])}
  },
  "select_values": {
    "profession": {
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesión"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biólogo"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietista"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfermero"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenador deportivo"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farmacéutico"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fisioterapéuta"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médico"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturópata"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psicólogo"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
    },
    "province": {
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
      "Fuera de España": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuera de España"])},
      "A Coruña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Coruña"])},
      "Álava": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Álava"])},
      "Albacete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albacete"])},
      "Alicante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alicante"])},
      "Almería": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almería"])},
      "Asturias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asturias"])},
      "Ávila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ávila"])},
      "Badajoz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badajoz"])},
      "Barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
      "Burgos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burgos"])},
      "Cáceres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cáceres"])},
      "Cádiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cádiz"])},
      "Cantabria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantabria"])},
      "Castellón": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castellón"])},
      "Ceuta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceuta"])},
      "Ciudad Real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad Real "])},
      "Córdoba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Córdoba"])},
      "Cuenca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenca"])},
      "Girona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girona"])},
      "Granada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granada"])},
      "Guadalajara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadalajara "])},
      "Guipúzcoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guipúzcoa"])},
      "Huelva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huelva"])},
      "Huesca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huesca"])},
      "Islas Baleares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Baleares"])},
      "Jaén": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaén"])},
      "La Rioja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Rioja"])},
      "Las Palmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Palmas"])},
      "León": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["León"])},
      "Lleida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lleida"])},
      "Lugo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugo"])},
      "Madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
      "Málaga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Málaga"])},
      "Melilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melilla"])},
      "Murcia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murcia"])},
      "Navarra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navarra"])},
      "Orense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orense"])},
      "Palencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palencia"])},
      "Pontevedra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontevedra"])},
      "Salamanca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salamanca"])},
      "Santa Cruz de Tenerife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cruz de Tenerife"])},
      "Segovia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segovia"])},
      "Sevilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevilla"])},
      "Soria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soria"])},
      "Tarragona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarragona"])},
      "Teruel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teruel"])},
      "Toledo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toledo"])},
      "Valencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencia"])},
      "Valladolid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valladolid"])},
      "Vizcaya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizcaya"])},
      "Zamora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamora"])},
      "Zaragoza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaragoza"])}
    },
    "speciality": {
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especialidad "])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acupuntura "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiología "])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermatología "])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietética "])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geriatría "])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ginecología "])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeopatía "])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinesiología "])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medicina biológica "])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina deportiva "])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina estética "])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina familiar "])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina general "])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina integrativa"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina interna "])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina naturista "])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Medicina tradicional china "])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Naturopatía "])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Neurología "])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nutrición "])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Oftalmología "])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Oncología "])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Osteopatía "])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pediatría "])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Podología "])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Psicoterapia "])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Psiquiatría "])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Reflexoterapia "])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Rehabilitación "])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Reumatología "])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terapia floral "])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terapias manuales "])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Traumatología "])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Urología "])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro "])}
    }
  }
}
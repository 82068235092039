import axios from 'axios'
export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
    },
    getters: {
        getUser: (state) => {
            return state.user
        }
    },
    actions: {
        createRegister({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/register', data).then(response => {
                    resolve(true);
                })
            });
        },
        remeberPasword({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/send_password', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    commit('alert/launchAlert', this.$i18n.t('auth_errors.' + error.data.error), { root: true });
                    reject(error);
                });
            })
        },
        resetPaswsword({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/reset_password', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    commit('alert/launchAlert', this.$i18n.t('auth_errors.' + error.data.error), { root: true });
                    reject(error);
                });
            });
        },
        login({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('auth/login', data).then(response => {
                    commit('login', {
                        user: response.data.user,
                        token: response.data.token,
                        remember: response.data.remember,
                    });
                    resolve(response);
                }).catch(error => {
                    commit('alert/launchAlert', this.$i18n.t('auth_errors.' + error.data.error), { root: true });
                    reject(error);
                });
            });
        },
        loginWithRemember({ commit }) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem('rememberToken');
                if (token) {
                    axios.get('auth/remember', {
                        headers: {
                            'Authorization': "Bearer " + token
                        }
                    }).then(response => {
                        commit('login', {
                            user: response.data.user,
                            token: response.data.token,
                            remember: response.data.remember,
                        });
                        resolve(response);
                    });
                }else{
                    resolve(true)
                }
            });
        },
        modificateUser({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios.post('auth/user/save', data).then(response => {
                    dispatch('refreshUser');
                    resolve(response);
                })
            });
        },
        refreshUser({ commit, state }) {
            return new Promise((resolve, reject) => {
                var promise = axios.get('auth/user');
                promise.then(function (result) {
                    resolve(result);
                    commit('setUser', result.data);
                })
            })
        },
    },
    mutations: {
        login(state, { user, token, remember }) {
            state.user = user;
            state.token = token;
            if (remember) {
                localStorage.setItem('rememberToken', remember);
            }
        },
        logout(state) {
            localStorage.removeItem('rememberToken');
            state.user = null;
            state.token = null;
        },
        setUser(state, newUser) {
            state.user = newUser;
        },
    }

}
import contents from './contents';
import auth from './auth';
import alert from './alert';
import createMultiTabState from 'vuex-multi-tab-state';
import {
  createStore
} from 'vuex'
export default createStore({
  modules: {
    contents: contents,
    auth: auth,
    alert: alert
  },
  plugins: [
    createMultiTabState(),
  ],
})
<template lang="html">
  <section class="popup-alert">
    <div id="popup" class="popup" :activePopup="alert.active">
      <div class="contenedor-popup">
        <div id="box" class="really-popup">
          <div id="message">
            <p>{{ alert.text }}</p>
          </div>
          <div id="box_options">
            <span id="ok" @click="closeAlert">Ok</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { mapGetters, mapActions, mapMutations } from "vuex";
  export default  {
    name: 'popup-alert',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
    ...mapMutations({
      closeAlert: "alert/closeAlert",
      }),
    },
    computed: {
    ...mapGetters({
      alert: "alert/getAlert",
    }),
    }
}
</script>

<style scoped lang="scss">
.popup-alert {
}
</style>

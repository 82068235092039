import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '@/views/home.vue'
import store from '@/store';
const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newsletter',
    name: 'news',
    component: () => import('@/views/newsletter.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documentos/dossieres',
    name: 'dossiers',
    component: () => import('@/views/dossiers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documentos/vademecums',
    name: 'vademecums',
    component: () => import('@/views/valdemecum_catalog.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documentos/fichas',
    name: 'filesProduct',
    component: () => import('@/views/files_product.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/perfil',
    name: 'profile',
    component: () => import('@/views/profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newsletter/:slug',
    name: 'new',
    component: () => import('@/views/page-new.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/registro',
    name: 'register',
    component: () => import('@/views/register.vue'),
    meta: {
      requiresAnonimous: true,
    }
  }, {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      requiresAnonimous: true,
    }
  }, {
    path: '/recordar-contrasena',
    name: 'rememberPassword',
    component: () => import('@/views/form-remember-password.vue'),
    meta: {
      requiresAnonimous: true,
    }
  }, {
    path: '/auth/reset-password/:token',
    name: 'validateLogin',
    props: true,
    component: () => import('@/views/reset-password.vue'),
    meta: {
      requiresAnonimous: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth && store.getters['auth/getUser'] == null) {
    sessionStorage.setItem('originalRoute', JSON.stringify(to));
    next({
      name: 'login'
    })
  } else if (to.meta && to.meta.requiresAnonimous && store.getters['auth/getUser'] ){
    next({
      name: 'home'
    })
  }
  next()

})

export default router
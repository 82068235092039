<template lang="html">
  <header class="header">
    <a
      id="aHome"
      href="http://www.santiveri.es/es/home"
      class="logoPrincipal"
    ></a>

    <ul class="social">
      <li class="social youtube">
        <a
          href="https://www.youtube.com/user/SantiveriCorporate"
          target="_blank"
          >r</a
        >
      </li>
      <li class="social instagram">
        <a href="https://www.instagram.com/santiveri_official/" target="_blank"
          >x</a
        >
      </li>
      <li class="social google">
        <a href="https://plus.google.com/+SantiveriEsp/" target="_blank">c</a>
      </li>
      <li class="social twitter">
        <a href="https://twitter.com/_santiveri_" target="_blank">a</a>
      </li>
      <li class="social pinterest">
        <a href="https://es.pinterest.com/santiveri1885/" target="_blank">d</a>
      </li>
      <li class="social facebook">
        <a href="https://www.facebook.com/santiveri.es" target="_blank">b</a>
      </li>
      <!-- SI ESTA AUTENTIFICADO -->
      <template v-if="login">
        <li class="cerrar-sesion">
          <router-link :to="{ name: 'login'}" class="cerrar-en" @click="logout()"
            ><i class="fa fa-sign-out" aria-hidden="true"></i
          ></router-link>
        </li>
        <li class="mis-datos">
          <router-link class="mis-datos-en" :to="{ name: 'profile'}">Mis Datos</router-link>
        </li>
      </template>
    </ul>
    <a class="menulogo" @click="toggleShowSubMenu"></a>
    <!-- <a class="menulogo on" id="aMenuLogoONmaster" visible="false"></a> -->
    <nav :showMenu="showMenu">
      <a class="logonav"></a>
      <div class="contNav">
        <ul class="menusub">
          <li>
            <a href="http://www.santiveri.com/es/somos-santiveri"
              >Somos Santiveri</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/donde-comprar"
              >Dónde comprar</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/abre-una-tienda"
              >Abre una tienda</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/internacional-mapa"
              >Internacional</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/newsletter">Newsletter</a>
          </li>
          <li class="download-pdf">
            <a
              href="https://www.santiveri.com/catalogo/catalogo.pdf"
              target="_blank"
              >Export Catalog</a
            >
          </li>
          <li class="download-pdf">
            <a
              href="https://www.santiveri.com/catalogo/catalogo-productos.pdf"
              target="_blank"
              >Nuestros productos</a
            >
          </li>
          <li><a href="http://www.santiveri.com/es/cuentanos">Contacto</a></li>
        </ul>
        <ul class="menu">
          <li class="menu1">
            <a href="http://www.santiveri.com/es/alimentacion">
              <img
                src="/img/menu1.jpg"
                class="img-responsive"
                alt="Productos"
              />
              <span>Productos <label>y punto</label></span>
            </a>
          </li>
          <li class="menu2">
            <a href="http://inspiraciones.santiveri.es?lang=es" target="_blank">
              <img
                src="/img/menu2.jpg"
                class="img-responsive"
                alt="Inspiraciones"
              />
              <span>Inspiraciones</span>
            </a>
          </li>
          <li class="menu3">
            <a href="http://www.santiveri.com/es/recetas">
              <img
                src="/img/menu3.jpg"
                class="img-responsive"
                alt="Mis recetas"
              />
              <span>Mis recetas</span>
            </a>
          </li>
          <li class="menu4">
            <a href="http://www.santiveri.com/es/cooltura">
              <img
                src="/img/menu4.jpg"
                class="img-responsive"
                alt="Nueva Cooltura"
              />
              <span><label>Nueva</label> cooltura</span>
            </a>
          </li>
          <li class="menu5">
            <a
              href="http://www.santiveri.com/memorias-de-un-apellido/movil/index.html"
              class="movil"
            >
              <img
                src="/img/menu5.jpg"
                class="img-responsive"
                alt="Memorias de un apellido"
              />
              <span>Memorias <label>de un apellido</label></span>
            </a>
            <a
              href="http://www.santiveri.com/memorias-de-un-apellido"
              class="pc"
            >
              <img
                src="/img/menu5.jpg"
                class="img-responsive"
                alt="Memorias de un apellido"
              />
              <span>Memorias <label>de un apellido</label></span>
            </a>
          </li>
        </ul>
        <ul class="menusubMov">
          <li>
            <a href="http://www.santiveri.com/es/somos-santiveri"
              >Somos Santiveri</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/donde-comprar"
              >Dónde comprar</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/abre-una-tienda"
              >Abre una tienda</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/internacional-mapa"
              >Internacional</a
            >
          </li>
          <li class="-not-in-it">
            <a
              href="http://www.santiveri.com/catalogo/catalogo.pdf"
              target="_blank"
              >Export Catalog</a
            >
          </li>
          <li class="-not-in-it">
            <a
              href="http://www.santiveri.com//catalogo/catalogo-productos.pdf"
              target="_blank"
              >Nuestros productos</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/newsletter" id="aNewsletter2"
              >Newsletter</a
            >
          </li>
          <li>
            <a href="http://www.santiveri.com/es/cuentanos" id="aContacto2"
              >Contacto</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script lang="js">
import { mapGetters, mapActions, mapMutations } from 'vuex';
  export default  {
    name: 'header',
    props: ['login'],
    mounted () {

    },
    data () {
      return {
        showMenu : false,
      }
    },
    methods: {
      toggleShowSubMenu: function() {
       this.showMenu = !this.showMenu;
      },
      ...mapMutations({
         logout: 'auth/logout' 
      })
    },
    computed: {
      ...mapGetters({
        user: "auth/getUser",
      }),
    }
}
</script>
<style scoped lang="scss">
.header {
}
</style>

<template lang="html">
  <footer class="footer">
    <div class="contentFoot">
      <div class="pie1">
        <div>
          <h3 @click="toggleShowSubFooter">SOBRE NOSOTROS</h3>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/somos-santiveri"
                >Somos Santiveri</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/donde-comprar"
                >Dónde comprar</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/abre-una-tienda"
                >Abre una tienda</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/internacional-mapa"
                >Santiveri Internacional</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/proyectos"
                >Nuestros proyectos</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter">SOBRE NUESTROS PRODUCTOS...</h3>
          <ul>
            <li>
              <a
                target="_blank"
                class="download-pdf"
                href="https://www.santiveri.com/catalogo/catalogo-productos.pdf"
                >Catálogo completo</a
              >
            </li>
            <li>
              <a
                target="_blank"
                class="download-pdf"
                href="https://www.santiveri.com/catalogo/catalogo.pdf"
                >Export Catalogue</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/complementos"
                >Complementos</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/alimentacion"
                >Alimentación</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/es/plantas-e-infusiones"
                >Plantas e infusiones</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/cosmetica"
                >Cosmética natural</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/deporte"
                >Deporte</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter" class="destacados">DESTACADOS</h3>
          <ul class="destacados">
            <li>
              <a
                target="_blank"
                href="https://pandextrin.santiveri.com/?slider_web"
                class="item-nuevo"
                >Pan Dextrin</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/defensas"
                >Defensas</a
              >
            </li>
            <li class="ayuno">
              <a
                target="_blank"
                href="https://www.santiveri.com/es/ayuno-intermitente-a17"
                >A17</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/ferroxir-forte"
                >Ferroxir</a
              >
            </li>
            <li>
              <a target="_blank" href="http://www.noglut.santiveri.com/"
                >Noglut</a
              >
            </li>
            <li><a target="_blank" href="http://florase.es/">Florase</a></li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/curcuma"
                >Cúrcuma</a
              >
            </li>
          </ul>
        </div>
        <div class="vida">
          <h3 class="vida">SANTIVERI: NUTRICIÓN Y VIDA</h3>
          <a class="vida" href="https://www.santiveri.com/es/home">
            <img
              src="/img/visita-web-corporativa.png"
              class="visita-web vida"
              alt="visita-web"
            />
          </a>
        </div>
      </div>
      <div class="pie2">
        <div>
          <h3 @click="toggleShowSubFooter">MUNDO SANTIVERI</h3>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://inspiraciones.santiveri.com?lang=es"
                >Inspiraciones</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/recetas"
                >Mis recetas</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/cooltura"
                >Nueva Cooltura</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.santiveri.com/memorias-de-un-apellido"
                >Memorias de un apellido</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter">CONTACTO...</h3>
          <ul>
            <li>
              <a target="_blank" href="https://www.santiveri.com/es/cuentanos"
                >Atención al cliente</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter">EMPLEO...</h3>
          <ul>
            <li>
              <a
                id="atrabajo"
                target="_blank"
                href="https://www.santiveri.com/es/trabaja-con-nosotros"
                >Trabaja con nosotros
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter">ÁREAS RESERVADAS</h3>
          <ul>
            <li>
              <a href="http://campus.santiveri.es/" target="_blank"
                >Campus Santiveri</a
              >
            </li>
            <!---li><a href="http://profesionales.santiveri.es" target="_blank">Profesionales Santiveri</a></li>-->
          </ul>
        </div>
      </div>
      <div class="pie3">
        <div>
          <h3 @click="toggleShowSubFooter">NUESTROS CANALES</h3>
          <ul class="social">
            <li class="social">
              <a href="https://www.facebook.com/santiveri.es" target="_blank"
                ><span>b</span> Facebook</a
              >
            </li>
            <li class="social">
              <a
                href="https://www.instagram.com/santiveri_official/"
                target="_blank"
                ><span>x</span> Instagram</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 @click="toggleShowSubFooter">LEGAL...</h3>
          <ul>
            <li>
              <a
                href="https://www.santiveri.com/es/aviso-legal"
                id="ContentPlaceHolder1_octrlpie_aAvisoLegal"
                target="_blank"
                >Aviso Legal</a
              >
            </li>
            <li>
              <a
                href="https://www.santiveri.com/es/politica-de-privacidad"
                id="ContentPlaceHolder1_octrlpie_aPrivacidad"
                target="_blank"
                >Política de privacidad</a
              >
            </li>
            <li>
              <a
                href="https://www.santiveri.com/es/mapa-web"
                id="ContentPlaceHolder1_octrlpie_aMapaWeb"
                target="_blank"
                >Mapa web</a
              >
            </li>
            <li>
              <a
                href="https://www.santiveri.com/es/cookies"
                id="ContentPlaceHolder1_octrlpie_aCookies"
                target="_blank"
                >Cookies</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="pie4">
        <h3>
          <a
            href="https://www.santiveri.com/es/newsletter"
            id="ContentPlaceHolder1_octrlpie_aNewsletter5"
            target="_blank"
            >SUSCRÍBETE A NUESTRAS NOVEDADES</a
          >
        </h3>

        <div class="idiomas">
          <a href="https://www.santiveri.com/es/home" id="aCambioIdiomaES"
            >español</a
          >
          <a href="https://www.santiveri.com/en/home" id="aCambioIdiomaEn"
            >english</a
          >
          <a href="https://www.santiveri.com/it/home" id="aCambioIdiomaIt"
            >italiano</a
          >
        </div>
        <a
          class="logopie"
          href="https://www.santiveri.com/"
          target="_blank"
        ></a>
        <a
          target="_blank"
          href="https://www.santiveri.com/es/abre-una-tienda"
          class="abrir-tienda"
          ><h3>Quieres abrir una tienda Santiveri?</h3>
          <span>Infórmate</span></a
        >
      </div>
    </div>

    <!-- <a class="cky-banner-element">Cookies Banner</a> -->
  </footer>
</template>

<script lang="js">

  export default  {
    name: 'footer',
    props: [],
    mounted () {

    },
    data () {
      return {
      }
    },
    methods: {
      toggleShowSubFooter: function() {
        event.target.classList.toggle("menos")
        event.target.parentNode.querySelector('ul').classList.toggle("active")
      },
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.corte {
  clear: both;
  padding-top: 50px;
}

footer {
  width: 100%;
  height: auto;
  background: #222;
  float: left;
  padding: 25px 0px 35px;
  position: relative;
}

footer.over {
  margin-top: -50px;
}

footer.prod {
  margin-top: 0px;
}

footer > div.contentFoot {
  width: 100%;
  max-width: 1300px;
  margin: 0px auto;
}

footer .vida {
  display: none;
}

footer .vida:hover {
  text-decoration: none;
}

footer > div.contentFoot > div {
  float: left;
  padding: 0px 20px;
  box-sizing: border-box;
}

footer > div.contentFoot .pie1 {
  width: 28%;
}

footer .visita-web {
  max-width: 231px;
  display: inline-block;
  float: left;
}

footer > div.contentFoot .pie2 {
  width: 22%;
}

footer > div.contentFoot .pie3 {
  width: 22%;
}

footer > div.contentFoot .pie4 {
  width: 28%;
}

footer > div.contentFoot h3 {
  color: #fff;
  width: auto;
  font-size: 17px;
  font-family: "Futura LT";
  float: left;
  text-transform: uppercase;
  border-bottom: 1px solid #919191;
  text-align: left;
  margin: 20px 0px;
  margin: 20px 0px 15px;
  padding-bottom: 3px;
}

footer .abrir-tienda {
  max-width: 300px;
  height: 204px;
  padding: 35px 45px;
  background: url(/img/cartel-pie.png) center/cover no-repeat;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  line-height: 1em;
  text-decoration: none;
  transition: 1s;
}

footer > div.contentFoot .abrir-tienda > h3 {
  font-family: "Manhattan Darling";
  font-size: 38px;
  text-align: center;
  color: white;
  line-height: 0.9em;
  text-transform: inherit;
  margin: 0;
  border-bottom: none;
}

footer > div.contentFoot .abrir-tienda > h3:hover {
  text-decoration: none;
}

footer > div.contentFoot .abrir-tienda > h3::before {
  content: "?";
  transform: rotate(180deg) translate(3px, -7px);
  display: inline-block;
}

footer > div.contentFoot .abrir-tienda > span {
  color: white;
  font-family: "Futura LT";
  text-decoration: underline;
  will-change: transform;
  -webkit-will-change: transform;
  transition: 0.7s;
}

footer > div.contentFoot .abrir-tienda:hover > span {
  transform: scale(1.1);
}

footer > div.contentFoot ul {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
}

footer > div.contentFoot ul li {
  width: 100%;
  float: left;
  font-size: 17px;
  font-family: "Futura LT";
}

footer > div.contentFoot ul li a {
  font-size: 17px;
  font-family: "Futura LT";
  color: #fff;
  line-height: 17px;
  margin-bottom: 3px;
  text-decoration: none;
}

footer > div.contentFoot ul li a:hover {
  text-decoration: none;
}

footer > div.contentFoot ul.social li.social a span {
  background: #fff none repeat scroll 0 0;
  border-radius: 15px;
  color: #323031;
  float: none;
  font-family: "socicon";
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  margin-right: 5px;
  text-align: center;
  text-decoration: none !important;
  width: 25px;
  text-shadow: none !important;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 8px;
}

footer .idiomas {
  float: left;
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

footer .idiomas a {
  color: #fff;
  float: unset;
  line-height: 50px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -9999px;
  width: 50px;
  display: inline-table;
}

footer .idiomas > a#aCambioIdiomaES {
  background: url("/img/spanishF.png") no-repeat scroll center center;
}

footer .idiomas > a#aCambioIdiomaEn {
  background: url("/img/englishF.png") no-repeat scroll center center;
}

footer .idiomas > a#aCambioIdiomaIt {
  background: url("/img/italianF.png") no-repeat scroll center center;
}

footer .idiomas a {
  color: #fff;
}

footer .pie4 h3 a {
  text-decoration: none;
  color: #fff;
}

footer .pie4 .check {
  width: 100%;
  float: left;
  margin-top: 15px;
  display: none;
}

footer .pie4 div.check input {
  display: none;
}

footer .pie4 div.check input[type="checkbox"] + label {
  background: transparent url(/img/check.png) no-repeat scroll left center;
  color: #fff;
  float: left;
  font-family: "Futura LT";
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  padding: 0 0 0 22px;
}

footer .pie4 div.check input[type="checkbox"]:checked + label {
  background: transparent url(/img/checkon.png) no-repeat scroll left center;
}

footer .pie4 div.check label a {
  color: inherit;
  float: none;
  text-decoration: underline;
}

footer .pie4 a.logopie {
  width: 100%;
  height: 90px;
  float: left;
  background: url(/img/logopie.png) no-repeat center center;
}
@media screen and (max-width: 767px) {
  footer > div div {
    width: 100% !important;
  }

  footer > div.contentFoot h3 {
    font-size: 15px;
    width: 100%;
    cursor: pointer;
    background: url(/img/mas.png) no-repeat center right;
    line-height: 34px;
    height: auto;
    padding: 0px;
    margin: 10px 0px;
    z-index: 2;
  }

  footer > div.contentFoot h3.menos {
    background: url(/img/menos.png) no-repeat center right;
  }

  footer > div.contentFoot .pie4 h3 {
    background: transparent;
  }

  footer > div.contentFoot .pie4 h3.menos {
    background: transparent;
  }

 
   footer > div.contentFoot ul {
    height: 0;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    padding-top: 0px;
    z-index: -1;
    position: relative;
  }
  footer > div.contentFoot ul.active {
    height: auto;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    padding-top: 5px;
    z-index: 0;
    display: block;
    position: relative;
  }
  footer .abrir-tienda{
    margin: 0 auto;
  }
  footer > div.contentFoot ul li {
    font-size: 15px;
  }

  footer > div.contentFoot ul li a {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 3px;
  }
}
</style>

<template>
  <Header :login="user"></Header>
  <div class="container column-start" :class="{ categorias: user }">
    <h1 v-if="user" class="titulo-categorias">Zona de profesionales Santiveri</h1>
    <router-view />
  </div>
  <Footer></Footer>
  <popupAlert></popupAlert>
</template>

<style lang="scss">
@import "@/styles/fonts.scss";
@import "@/styles/general.scss";
@import "@/styles/listado.scss";
@import "@/styles/santiveri.scss";
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
</style>
<script>
// @ is an alias to /src
import utils from "@/utils/utils.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import popupAlert from "@/components/popup-alert.vue";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    popupAlert
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
  },
  mounted() {
    this.checkUser();
  },

  methods: {
    checkUser() {
      window.onbeforeunload = function () {
        localStorage.removeItem('vuex-multi-tab');
      };
      window.localStorage.removeItem('vuex-multi-tab');
      let t = this;
      if (this.user == null && localStorage.getItem('rememberToken')) {
        sessionStorage.setItem('chekedRememberUser', true);
        var promise = this.$store.dispatch('auth/loginWithRemember');
        promise.then(function (result) {
          if (t.user) {
            t.redirect();
          }
        });
      }
    },
    redirect() {
      var name = sessionStorage.getItem('originalRoute') ? JSON.parse(sessionStorage.getItem('originalRoute')).name : 'home';
      sessionStorage.removeItem('originalRoute');
      this.$router.push({ name: name });
    }
  },

  watch: {
    "$route.path"() {
      utils.loaderScrollToTop();
    },
  },
};
</script>

import axios from 'axios'
import utils from '@/utils/utils.js'
export default {
    namespaced: true,
    state: {
        news: {
            pages: [],
            lastFilters: null,
            maxItems: null,
            items: {}
        },
        dossiers: {
            pages: [],
            lastFilters: null,
            maxItems: null,
            items: {}
        },
        valdemecum: {
            pages: [],
            lastFilters: null,
            maxItems: null,
            items: {}
        },
        filesProduct: {
            pages: [],
            lastFilters: null,
            maxItems: null,
            items: {}
        },
        loading: false,
    },
    getters: {
        getNews: (state) => {
            return state.news.items;
        },
        getNewsLastFilter: (state) => {
            return state.news.lastFilters;
        },
        getNewsMaxItems: (state) => {
            return state.news.maxItems;
        },
        getNewsReachEnding: (state) => {
            return state.news.maxItems <= Object.keys(state.news.items).length;
        },
        getNew: (state) => (slug) => {
            return state.news.items[slug] ? state.news.items[slug] : null;
        },
        getDossier: (state) => {
            return state.dossiers.items;
        },
        getDossiersMaxItems: (state) => {
            return state.dossiers.maxItems;
        },
        getValdemecum: (state) => {
            return state.valdemecum.items;
        },
        getValdemecumMaxItems: (state) => {
            return state.valdemecum.maxItems;
        },
        getFilesProduct: (state) => {
            return state.filesProduct.items;
        },
        getFilesProductMaxItems: (state) => {
            return state.filesProduct.maxItems;
        },
        getLoading: (state) => {
            return state.loading;
        }
    },
    actions: {
        //{ page = 1, textFilter = null} --> creamos un objeto de variables que luego pasamos a x funcion
        loadNews({
            commit,
            state
        }, {
            page = 1,
            textFilter = null
        } = {}) {
            if (textFilter !== null && textFilter.length == 0) {
                textFilter = null
            }
            if (state.news.lastFilters == textFilter) {
                if (state.news.pages.includes(page)) {
                    return;
                }
            } else {
                commit('cleanNews');
                page = 1;
            }
            if (state.news.maxItems !== null && Object.keys(state.news.items).length >= state.news.maxItems) {
                return;
            }
            var data = {
                length: 9,
                filters: utils.generateTextFilters(textFilter, ['title', 'header_img_subtitle']),
                page: page
            }
            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('entry', data);
                promise.then(function (result) {
                    commit('addNews', {
                        news: result.data.data,
                        page: page,
                        filters: textFilter,
                        maxItems: result.data.recordsFiltered
                    });
                    resolve(result);
                    commit('endLoad')
                });
            })
        },
        loadNew({ commit, state }, { slug = 'default' } = {}) {
            if (state.news.items[slug]) {
                return true;
            }
            return new Promise((resolve, reject) => {
                axios.get("entry/" + slug)
                    .then(function (result) {
                        commit('addNew', result.data);
                        resolve(result);
                    });
            });
        },
        loadDossiers({
            commit,
            state
        }, {
            page = 1,
            textFilter = null
        } = {}) {
            if (textFilter !== null && textFilter.length == 0) {
                textFilter = null
            }

            if (state.dossiers.lastFilters == textFilter) {
                if (state.dossiers.pages.includes(page)) {
                    return;
                }
            } else {
                commit('cleanDossiers');
                page = 1;
            }
            if (state.dossiers.maxItems !== null && Object.keys(state.dossiers.items).length >= state.dossiers.maxItems) {
                return;
            }

            var fixedFilter = [{
                name: "category",
                search: 2
            }];
            var filters = utils.generateTextFilters(textFilter, ['title', 'description']);

            var data = {
                length: 12,
                //order : [{name: 'order_date', direction : 'asc'}];
                filters: fixedFilter.concat(filters),
                page: page,
            }
            commit('loading');
            return new Promise((resolve, reject) => {
                var promise = axios.post('files', data);
                promise.then(function (result) {
                    commit('addDossiers', {
                        dossiers: result.data.data,
                        page: page,
                        filters: textFilter,
                        maxItems: result.data.recordsFiltered
                    });
                    resolve();
                    commit('endLoad');
                });
            })
        },
        loadValdemecum({
            commit,
            state
        }, {
            page = 1,
            textFilter = null
        } = {}) {
            if (textFilter !== null && textFilter.length == 0) {
                textFilter = null
            }
            if (state.valdemecum.lastFilters == textFilter) {
                if (state.valdemecum.pages.includes(page)) {
                    return;
                }
            } else {
                commit('cleanValdemecum');
                page = 1;
            }
            if (state.valdemecum.maxItems !== null && Object.keys(state.valdemecum.items).length >= state.valdemecum.maxItems) {
                return;
            }
            var fixedFilter = [{
                name: "category",
                search: 0
            }];
            var filters = utils.generateTextFilters(textFilter, ['title', 'description']);
            var data = {
                //order : [{name: 'order_date', direction : 'asc'}];
                length: 12,
                filters: fixedFilter.concat(filters),
                page: page,
            }
            commit('loading');
            return new Promise((resolve, reject) => {
                var promise = axios.post('files', data);
                promise.then(function (result) {
                    commit('addValdemecum', {
                        valdemecum: result.data.data,
                        page: page,
                        filters: textFilter,
                        maxItems: result.data.recordsFiltered
                    });
                    resolve();
                    commit('endLoad');
                });
            })
        },
        loadFilesProduct({
            commit,
            state
        }, {
            page = 1,
            textFilter = null
        } = {}) {

            if (textFilter !== null && textFilter.length == 0) {
                textFilter = null
            }
            if (state.filesProduct.lastFilters == textFilter) {
                if (state.filesProduct.pages.includes(page)) {
                    return;
                }
            } else {
                commit('cleanFilesProduct');
                page = 1;
            }
            if (state.filesProduct.maxItems !== null && Object.keys(state.filesProduct.items).length >= state.filesProduct.maxItems) {
                return;
            }
            var fixedFilter = [{
                name: "category",
                search: 1
            }];
            var filters = utils.generateTextFilters(textFilter, ['title', 'description']);
            var data = {
                length: 12,
                //order : [{name: 'order_date', direction : 'asc'}];
                filters: fixedFilter.concat(filters),
                page: page,
            }
            commit('loading');
            return new Promise((resolve, reject) => {
                var promise = axios.post('files', data);
                promise.then(function (result) {
                    commit('addFilesProduct', {
                        filesProduct: result.data.data,
                        page: page,
                        filters: textFilter,
                        maxItems: result.data.recordsFiltered

                    });
                    resolve();
                    commit('endLoad');
                });
            })
        }
    },
    mutations: {
        //States
        setLoading(state, st) {
            state.loading = st;
        },
        loading(state) {
            state.loading = true;
        },
        endLoad(state) {
            state.loading = false;
        },
        // News loadNews({ commit, state }, {page = 1, textFilter = null} = {}) {
        addNews(state, {
            news,
            filters,
            page,
            maxItems
        }) {
            if (news) {
                news.forEach(element => {
                    state.news.items[element.slug] = element;
                });
            }
            if (filters) {
                state.news.lastFilters = filters;
            }
            if (page) {
                state.news.pages.push(page);
            }
            if (maxItems) {
                state.news.maxItems = maxItems;
            }
        },
        addNew(state, newData) {
            state.news.items[newData.slug] = newData;
        },
        cleanNews(state) {
            state.news.items = {}
            state.news.pages = []
            state.news.lastFilters = null
        },

        // Dossiers
        //vuex solo acepta dos parametros , por eso metemos en un objeto los siguientes
        addDossiers(state, {
            dossiers,
            page,
            filters,
            maxItems
        }) {
            if (dossiers) {
                dossiers.forEach(element => {
                    state.dossiers.items[element.slug] = element;
                });
            }
            if (page) {
                state.dossiers.pages.push(page);
            }
            if (filters) {
                state.dossiers.lastFilters = filters;
            }
            if (maxItems) {
                state.dossiers.maxItems = maxItems;
            }
        },
        cleanDossiers(state) {
            state.dossiers.items = {}
            state.dossiers.pages = []
            state.dossiers.lastFilters = null
        },

        // Valdemecum - catálogo
        addValdemecum(state, {
            valdemecum,
            page,
            filters,
            maxItems
        }) {
            if (valdemecum) {
                valdemecum.forEach(element => {
                    state.valdemecum.items[element.slug] = element;
                });
                if (page) {
                    state.valdemecum.pages.push(page);
                }
                if (filters) {
                    state.valdemecum.lastFilters = filters;
                }
                if (maxItems) {
                    state.valdemecum.maxItems = maxItems;
                }
            }
        },
        cleanValdemecum(state) {
            state.valdemecum.items = {}
            state.valdemecum.pages = []
            state.valdemecum.lastFilters = null
        },

        // Fichas productos
        addFilesProduct(state, {
            filesProduct,
            page,
            filters,
            maxItems
        }) {
            if (filesProduct) {
                filesProduct.forEach(element => {
                    state.filesProduct.items[element.slug] = element;
                });
            }
            if (page) {
                state.filesProduct.pages.push(page);
            }
            if (filters) {
                state.filesProduct.lastFilters = filters;
            }
            if (maxItems) {
                state.filesProduct.maxItems = maxItems;
            }
        },
        cleanFilesProduct(state) {
            state.filesProduct.items = {}
            state.filesProduct.pages = []
            state.filesProduct.lastFilters = null
        }
    }
}